import {
  DOCUMENT_NAMESPACE,
  FOLDER_NAMESPACE,
  USER_NAMESPACE,
} from "@/components/library/document-permissions/constants";
import { SupabaseClient } from "@supabase/supabase-js";
import axios from "axios";
import cx from "classnames";
import HTMLModule from "docxtemplater-html-module";
import HTMLXlsxModule from "docxtemplater-html-xlsx-module";
import ImageModule from "docxtemplater-image-module";
import XlsxModule from "docxtemplater-xlsx-module";
import { saveAs } from "file-saver";
import ms from "ms";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import * as XLSX from "xlsx";
import { utils } from "xlsx";
import {
  CONTENT_LENGHTS,
  DEAL_CREATED_STATUS,
  IMAGE_MIME_TYPES,
  LOST_STATUS,
  MIME_TYPE_TO_EXTENSION,
  PRE_SALES_STATUS,
  QUALIFIED_STATUS,
  WON_STATUS,
} from "./constants";
import {
  EngagementsType,
  NextStepType,
  RequirementsType,
} from "./crm-sync/utlis";
import KetoClient from "./permissions/keto-client";
import { z } from "zod";

export function isExpression(str: string) {
  const patterns = [
    // TERNARIES: a ? b : c
    /.+\?.+:.+/,
    // EQUALITY/INEQUALITY: a == 1, a != 1
    /.+==.+|.+!=.+/,
    // RELATIONAL: a > 1, a < 1, a >= 1, a <= 1
    /.+>.|.+<.|.+>=.|.+<=./,
    // AND: a && b
    /.+&&.+/,
    // OR: a || b
    /.+\|\|.+/,
    // ADDITION: a + b
    /.+\+.+/,
    // SUBTRACTION: a - b
    /.+-.+/,
    // MULTIPLICATION: a * b
    /.+\*.+/,
    // MODULO: a % b
    /.+%.+/,
    // DIVISION: a / b
    /.+\/.+/,
    // ASSIGNMENTS: a = 1
    /.+=.*/,
    // OPERATOR PRECEDENCE with parenthesis: (a && b) || c
    /\(.+\).*/,
    // EXPONENTIAL NUMBERS: 12e3
    /\d+e\d+/i,
  ];

  return patterns.some((pattern) => pattern.test(str));
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const runAsyncFnWithoutBlocking = (
  fn: (...args: any) => Promise<any>,
) => {
  fn();
};

export const dataToWorkbook = (
  rows: Record<string, any[]>,
  cols: Record<string, any[]>,
  sheets: any[],
) => {
  const wb = utils.book_new();
  for (const sheet of sheets) {
    const sheetName = sheet.name;
    const ws = utils.aoa_to_sheet([cols[sheet.id].map((col) => col.title)]);
    const cellToLink: Record<string, string> = {};
    for (const row of rows[sheet.id]) {
      const rowArray = cols[sheet.id].map((col) => {
        if (col.type === "file-link") {
          const firstValue = row[col.id || ""]?.value?.[0];
          if (firstValue) {
            cellToLink[
              firstValue.file_name
            ] = `${process.env.NEXT_PUBLIC_SITE_URL}/library/documents/guest/${firstValue.id}`;
            return firstValue?.file_name;
          }
          return firstValue ? firstValue.file_name : "";
        }
        return row[col.id || ""]?.value || "";
      });
      console.log("rowArray", rowArray);
      XLSX.utils.sheet_add_aoa(ws, [rowArray], { origin: -1 });
    }
    fixWidth(ws);
    for (const cell in ws) {
      if (cellToLink[ws[cell]?.v]) {
        ws[cell].l = {
          Target: cellToLink[ws[cell]?.v],
          Tooltip: "Link to file",
        };
      }
    }
    utils.book_append_sheet(wb, ws, sheetName);
  }
  return wb;
};

export const deleteUnknownFields = (obj: any) => {
  for (const key in obj) {
    if (
      ![
        "description",
        "iconUrl",
        "page_number",
        "source",
        "policyType",
        "mime_type",
        "path",
        "text_as_html",
        "original_document_id",
      ].includes(key)
    ) {
      delete obj[key];
    }
  }
  return obj;
};

export const prepareGmailDoc = (thread: any) => {
  let text = "";
  thread?.messages.forEach((message: any) => {
    text += `\nFrom: ${message.from_email || ""}\n\nSubject: ${
      message?.subject || ""
    }\n\nText: ${message?.text || ""}\n\n`;
  });

  return text;
};
const pushChildren = (
  tree: any[],
  templates: any[],
  index: number,
  depth: number,
) => {
  tree.push({ ...templates[index], depth: depth });
  const lastIndex = tree.length - 1;
  for (let i = 0; i < templates.length; i++) {
    if (templates[i].parent_id === templates[index].id) {
      tree[lastIndex].hasChildren = true;
      pushChildren(tree, templates, i, depth + 1);
    }
  }
};

export const buildTree = (data: any[]) => {
  if (!data) {
    return [];
  }
  const tree: any = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].parent_id === null) {
      pushChildren(tree, data, i, 0);
    }
  }
  // Calculates the "stem" length
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].hasChildren) {
      for (let j = i; j < tree.length; j++) {
        if (tree[j].parent_id === tree[i].id) {
          tree[i].stem = j - i;
        }
      }
    }
  }
  return tree;
};

const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
const validBase64 =
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

export function base64Parser(dataURL: string) {
  if (typeof dataURL !== "string" || !base64Regex.test(dataURL)) {
    return false;
  }

  const stringBase64 = dataURL.replace(base64Regex, "");

  if (!validBase64.test(stringBase64)) {
    throw new Error(
      "Error parsing base64 data, your data contains invalid characters",
    );
  }

  // For nodejs, return a Buffer
  if (typeof Buffer !== "undefined" && Buffer.from) {
    return Buffer.from(stringBase64, "base64");
  }

  // For browsers, return a string (of binary content) :
  const binaryString = window.atob(stringBase64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}

export const getDocxTemplaterModules = (
  modules?: any[],
  isServer?: boolean,
) => [
  ...(modules || []),
  new ImageModule({
    centered: false,
    getImage(tagValue: string, tagName: string) {
      console.log({ tagValue, tagName });
      const base64Value = base64Parser(tagValue);
      if (base64Value) {
        return base64Value;
      }
      return axios
        .get(tagValue, { responseType: "arraybuffer" })
        .then(async (response) => {
          return response.data;
        });
    },
    getSize(img: any): [number, number] {
      // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
      return [100, 100];
    },
  }),
  new HTMLModule({
    img: {
      Module: ImageModule,
      getValue(element: any) {
        const src = element.attribs.src;
        const base64Value = base64Parser(src);
        if (base64Value) {
          return base64Value;
        }
        return axios
          .get(src, { responseType: "arraybuffer" })
          .then(async (response) => {
            return response.data;
          });
      },
      getSize(data: any): [number, number] {
        // it also is possible to return a size in centimeters, like this : return [ "2cm", "3cm" ];
        if (data.element.attribs.width && data.element.attribs.height) {
          return [
            parseInt(data.element.attribs.width),
            parseInt(data.element.attribs.height),
          ];
        }
        return [100, 100];
      },
    },
  }),
  new XlsxModule(),
  new HTMLXlsxModule(),
];

export function templateSchemaKeyValuesToJsonSchema(templateKV: any) {
  const ret: any = {};
  for (const key in templateKV) {
    const valTemplateSchema = templateKV[key];
    const valJsonSchema: any = {};
    ret[key] = valJsonSchema;
    const properties = Object.keys(valTemplateSchema)
      .filter((key) => {
        return !key.startsWith("__");
      })
      .map((k) => ({
        [k]: valTemplateSchema[k],
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});
    valJsonSchema.type = "object";
    valJsonSchema.description = valTemplateSchema.__description || "";
    switch (valTemplateSchema.__type) {
      case "text":
      case undefined:
      case "image":
        valJsonSchema.type = "object";
        valJsonSchema["properties"] =
          templateSchemaKeyValuesToJsonSchema(properties) || {};
        valJsonSchema["required"] = Object.keys(properties) || [];
        if (valTemplateSchema.__default) {
          valJsonSchema.description += `The suggestion should be a personalized version of the default. The default value is : ${valTemplateSchema.__default}`;
        } else {
          valJsonSchema.description += `\nIt is very important to keep the length of this variable to ${
            CONTENT_LENGHTS[valTemplateSchema.__length || 1]
          }. Do not include a suggestion.`;
        }
        if (valTemplateSchema.__type === "image") {
          valJsonSchema.description +=
            "The value or suggestion should be an image url.";
        }

        // No properties indicates a leaf variable, i.e. text/image
        if (Object.keys(properties).length === 0) {
          valJsonSchema.required.push("__value");
          valJsonSchema.properties = {
            ...valJsonSchema.properties,
            __value: {
              type: "string",
              description: "The value of the text",
            },
          };
        }
        if (valTemplateSchema.__default) {
          valJsonSchema.required.push("__suggestion");
          valJsonSchema.properties.__suggestion = {
            type: "string",
            description:
              "A personalized suggestion for the default value that is almost exactly the same.",
          };
          valJsonSchema.required.push("__justification");
          valJsonSchema.properties.__justification = {
            type: "string",
            description:
              "Justification for the suggestion, why this personalization is great for the client.",
          };
        }
        break;
      case "object":
        valJsonSchema.type = "object";
        valJsonSchema.properties =
          templateSchemaKeyValuesToJsonSchema(properties);
        valJsonSchema.required = Object.keys(properties);
        break;
      case "array":
        valJsonSchema.type = "array";
        valJsonSchema.items = {
          type: "object",
          properties: templateSchemaKeyValuesToJsonSchema(properties),
        };
        valJsonSchema.items.required = Object.keys(properties);
        break;
    }
  }
  return ret;
}

function mergePageElements(elements: any[]) {
  const finalChunks: any[] = [];
  let currentChunk: any = { text: "", metadata: {} };
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (
      i !== 0 &&
      currentChunk.metadata?.page_number !== element.metadata?.page_number
    ) {
      finalChunks.push(currentChunk);
      currentChunk = { text: "", metadata: {} };
    }
    currentChunk.text += element.text ? element.text + " " : "";
    currentChunk.metadata.page_number = element.metadata?.page_number;
  }
  finalChunks.push(currentChunk);

  return finalChunks;
}

export function fixWidth(worksheet: XLSX.WorkSheet) {
  const data = XLSX.utils.sheet_to_json<any>(worksheet);
  if (!data) {
    return "";
  }
  console.log("JSON DATA: ", data);
  const colLengths = Object.keys(data[0]).map((k) => k.toString().length);
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    Object.values(d).forEach((element: any, index) => {
      const length = element.toString().length;
      if (colLengths[index] < length) {
        colLengths[index] = length;
      }
    });
  }
  worksheet["!cols"] = colLengths.map((l) => {
    return {
      wch: l,
    };
  });
  return Object.keys(data[0])[0];
}

export function cleanVerifiedAnswers(answers: any[]) {
  return answers
    .map((answer: any) =>
      JSON.stringify({
        id: answer.id,
        question: answer.question,
        answer: answer.answer,
      }),
    )
    .join("");
}
export function cleanDocumentChunks(
  chunks: any[],
  deal_documents: boolean = false,
) {
  if (deal_documents) {
    return chunks
      .map((chunk: any) => {
        let text = chunk?.metadata?.text_as_html || chunk.content;
        const metadata = chunk.metadata;
        if (chunk.prev_chunk) {
          text = chunk.prev_chunk.content + text;
        }
        if (chunk.next_chunk) {
          text = text + chunk.next_chunk.content;
        }
        return JSON.stringify({
          chunk_id: chunk.id,
          text: text,
          file_name: chunk.file_name,
          document_id: chunk.document_id,
          bucket: "deal_documents",
        });
      })
      .join("");
  } else {
    return chunks
      .map((chunk: any) => {
        let text = chunk?.metadata?.text_as_html || chunk.content;
        if (chunk.prev_chunk) {
          text = chunk.prev_chunk.content + text;
        }
        if (chunk.next_chunk) {
          text = text + chunk.next_chunk.content;
        }
        return JSON.stringify({
          chunk_id: chunk.id,
          text: text,
          file_name: chunk.file_name,
          document_id: chunk.document_id,
        });
      })
      .join("");
  }
}

export function dealsToSlackBlocks(deals: any[]) {
  if (!deals.length) {
    return [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `No deals found.`,
        },
      },
    ];
  }
  const blocks = [];
  for (let i = 0; i < deals.length; i++) {
    blocks.push({
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${deals[i].name}*`,
      },
      accessory: {
        type: "button",
        text: {
          type: "plain_text",
          text: "View Deal",
        },
        value: JSON.stringify({
          id: deals[i].id,
          name: deals[i].name,
        }),
        url: `${process.env.NEXT_PUBLIC_SITE_URL}/deals/${deals[i].id}/home`,
      },
    });
    blocks.push({
      type: "context",
      elements: [
        ...((deals[i]?.clients?.clients as any)?.logo_url
          ? [
              {
                type: "image",
                image_url: (deals[i]?.clients?.clients as any)?.logo_url,
                alt_text: "Logo",
              },
            ]
          : []),
        {
          type: "mrkdwn",
          text: `*${deals[i]?.name}* (${deals[i]?.clients?.name})`,
        },
      ],
    });
    if (i < deals.length - 1) {
      blocks.push({
        type: "divider",
      });
    }
  }
  return blocks;
}

function sourceToUrl(source: string) {
  switch (source) {
    case "vanta":
      return "https://app.dealpage.ai/vanta.png";
    case "drive":
      return "https://app.dealpage.ai/drive.png";
    case "confluence":
      return "https://app.dealpage.ai/confluence.svg";
    case "notion":
      return "https://app.dealpage.ai/notion.png";
    case "Hubspot":
      return "https://app.dealpage.ai/hubspot.svg";
    case "Salesforce":
      return "https://app.dealpage.ai/salesforce.png";
    case "gmail":
      return "https://app.dealpage.ai/gmail.png";
    default:
      return "";
  }
}

export function documentToSlackBlocks(document: any) {
  const blocks: any[] = [];
  blocks.push({
    type: "section",
    text: {
      type: "mrkdwn",
      text: `*${document.file_name}*`,
    },
    ...(["vanta", "drive", "confluence", "notion"].includes(
      document.metadata?.source,
    ) && {
      accessory: {
        type: "image",
        image_url: sourceToUrl(document.metadata?.source),
        alt_text: "Source",
      },
    }),
    accessory: {
      type: "button",
      text: {
        type: "plain_text",
        text: "View Document",
      },
      value: JSON.stringify({
        id: document.id,
        file_name: document.file_name,
        summary: document.summary?.substring(0, 500),
      }),
      url: document?.deal_id
        ? `${process.env.NEXT_PUBLIC_SITE_URL}/deals/${document?.deal_id}/documents/viewer/${document?.id}`
        : `${process.env.NEXT_PUBLIC_SITE_URL}/library/documents/${document.id}`,
    },
  });
  if (
    [
      "vanta",
      "drive",
      "confluence",
      "notion",
      "Hubspot",
      "gmail",
      "Salesforce",
    ].includes(document.metadata?.source)
  ) {
    blocks.push({
      type: "context",
      elements: [
        {
          type: "image",
          image_url: sourceToUrl(document.metadata?.source),
          alt_text: "Source",
        },
        {
          type: "mrkdwn",
          text: `Imported from ${capitalize(document.metadata?.source)}`,
        },
      ],
    });
  }
  return blocks;
}

export function documentsToSlackBlocks(documents: any[]) {
  if (!documents.length) {
    return [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `No documents found.`,
        },
      },
    ];
  }
  const blocks = [];
  for (let i = 0; i < documents.length; i++) {
    blocks.push(...documentToSlackBlocks(documents[i]));
    if (i < documents.length - 1) {
      blocks.push({
        type: "divider",
      });
    }
  }
  return blocks;
}

export const docNotificationToSlackBlocks = (
  subject: string,
  dealId: string,
  documentId: string,
  dealName: string,
  clientLogoUrl: any,
) => {
  return [
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: subject,
      },
    },
    {
      type: "actions",
      block_id: "actions1",
      elements: [
        {
          type: "button",
          text: {
            type: "plain_text",
            text: "View document",
          },
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/deals/${dealId}/documents/viewer/${documentId}`,
          action_id: "button_1",
        },
      ],
    },
    {
      type: "context",
      elements: clientLogoUrl
        ? [
            {
              type: "image",
              image_url: clientLogoUrl,
              alt_text: "Client Logo",
            },
            {
              type: "mrkdwn",
              text: `Deal: *${dealName}*`,
            },
          ]
        : [
            {
              type: "mrkdwn",
              text: `Deal: *${dealName}*`,
            },
          ],
    },
  ];
};
export function dealToSlackBlocks(deal: any) {
  const blocks = [];
  blocks.push({
    type: "section",
    text: {
      type: "plain_text",
      text: deal.name,
    },
    accessory: {
      type: "button",
      text: {
        type: "plain_text",
        text: "View Deal",
      },
      value: JSON.stringify({
        id: deal.id,
        name: deal.name,
        status: deal.status,
        amount: deal.amount,
        clients: {
          name: deal.clients?.name,
        },
      }),
      url: `${process.env.NEXT_PUBLIC_SITE_URL}/deals/${deal?.id}/home`,
    },
  });
  blocks.push({
    type: "context",
    elements: deal.clients?.logo_url
      ? [
          {
            type: "image",
            image_url: deal.clients?.logo_url,
            alt_text: "Logo",
          },
          {
            type: "mrkdwn",
            text: `*${deal.clients?.name}*`,
          },
        ]
      : [
          {
            type: "mrkdwn",
            text: `*${deal?.name}* (${deal.clients?.name})`,
          },
        ],
  });
  return blocks;
}

export function docChunksToSlackBlocks(chunks: any[], imageUrls: string[]) {
  if (!chunks.length) {
    return [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `No documents found.`,
        },
      },
    ];
  }
  const blocks = [];
  let imageIndex = 0;
  const seenDocumentIds = new Set();
  for (let i = 0; i < chunks.length; i++) {
    if (seenDocumentIds.has(chunks[i].document_id)) {
      continue;
    }
    let containsImage = IMAGE_MIME_TYPES.includes(
      chunks[i].metadata?.mime_type,
    );
    if (containsImage && imageUrls[imageIndex]) {
      blocks.push({
        type: "image",
        image_url: imageUrls[imageIndex],
        alt_text: chunks[i].file_name,
      });
      imageIndex++;
    }
    const value = JSON.stringify({
      id: chunks[i].id,
      document_id: chunks[i].document_id,
      content: chunks[i]?.metadata?.text_as_html || chunks[i].content,
      page_number: chunks[i].metadata?.page_number,
      file_name: chunks[i].file_name,
    });
    blocks.push({
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${chunks[i].file_name}*`,
      },
      accessory: {
        type: "button",
        text: {
          type: "plain_text",
          text: containsImage ? "View Image" : "View Document",
        },
        value: value.substring(0, 2000),
        url: chunks[i]?.deal_id
          ? `${process.env.NEXT_PUBLIC_SITE_URL}/deals/${chunks[i]?.deal_id}/documents/viewer/${chunks[i].document_id}`
          : `${process.env.NEXT_PUBLIC_SITE_URL}/library/documents/${chunks[i].document_id}`,
      },
    });
    blocks.push({
      type: "context",
      elements:
        chunks[i].metadata?.source === "vanta" ||
        chunks[i].metadata?.source === "drive" ||
        chunks[i].metadata?.source === "confluence" ||
        chunks[i].metadata?.source === "notion"
          ? [
              {
                type: "image",
                image_url: sourceToUrl(chunks[i].metadata?.source),
                alt_text: "Source",
              },
              {
                type: "mrkdwn",
                text: `*${chunks[i].file_name.substring(0, 50)}* ${
                  chunks[i].metadata?.page_number
                    ? `_Page ${chunks[i].metadata?.page_number}_`
                    : ""
                }`,
              },
            ]
          : [
              {
                type: "mrkdwn",
                text: `*${chunks[i].file_name}* ${
                  chunks[i].metadata?.page_number
                    ? `_Page ${chunks[i].metadata?.page_number}_`
                    : ""
                }`,
              },
            ],
    });
    if (i < chunks.length - 1) {
      blocks.push({
        type: "divider",
      });
    }
    seenDocumentIds.add(chunks[i].document_id);
  }
  return blocks;
}

export function verifiedAnswerToSlackBlock(answer: any) {
  return {
    type: "section",
    text: {
      type: "mrkdwn",
      text: `*${answer.question}*\n>${answer.answer}\n`,
    },
    accessory: {
      type: "button",
      text: {
        type: "plain_text",
        text: "View Answer",
      },
      url: `${process.env.NEXT_PUBLIC_SITE_URL}/library/knowledge?type=verified`,
    },
  };
}

export function verifiedAnswersToSlackBlocks(answers: any[]) {
  if (!answers.length) {
    return [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `No verified answers found.`,
        },
      },
    ];
  }
  return [
    ...answers.map((answer) => verifiedAnswerToSlackBlock(answer)),
    {
      type: "divider",
    },
  ];
}

export function cn(...inputs: any[]) {
  return twMerge(cx(inputs));
}

export const timeAgo = (
  timestamp: Date | string,
  timeOnly?: boolean,
): string => {
  if (!timestamp) return "never";
  const diff = Date.now() - new Date(timestamp).getTime();
  if (!diff) return "never";
  if (diff < 60000) return "just now";
  return `${ms(diff)}${timeOnly ? "" : " ago"}`;
};

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number;
      };
      error.status = res.status;
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }

  return res.json();
}

export function nFormatter(num: number, digits?: number) {
  if (!num) return "0";
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits || 1).replace(rx, "$1") + item.symbol
    : "0";
}

export function capitalize(str: string) {
  if (!str || typeof str !== "string") return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const truncate = (str: string, length: number) => {
  if (!str || str.length <= length) return str;
  return `${str.slice(0, length)}...`;
};

export const downloadFile = async (
  name: string,
  fileType: string | null | undefined,
  bucket: string | null | undefined,
  path: string | null | undefined,
  supabaseClient: SupabaseClient | null,
) => {
  if (fileType && bucket && path && supabaseClient) {
    const { data, error } = await supabaseClient!.storage
      .from(bucket)
      .download(path);
    if (error) {
      toast.error(`Error downloading file: ${error.message}`);
      return;
    }
    const fileName = name.endsWith(`.${MIME_TYPE_TO_EXTENSION[fileType]}`)
      ? name
      : `${name}.${MIME_TYPE_TO_EXTENSION[fileType]}`;
    saveAs(data, fileName);
  }
};

export const needsReview = (
  timestamp: string | undefined | null,
  reviewRequiredMonths: number | undefined | null,
) => {
  // If the document has a last reviewed date, check if its more than "reviewRequired" months ago.
  // If not and the document has a "reviewRequired" value, the review is expired.
  // If both are null, the document does not need to be reviewed.
  const threshold = new Date();
  if (!reviewRequiredMonths) return false;
  threshold.setMonth(threshold.getMonth() - reviewRequiredMonths);
  return timestamp
    ? threshold.getTime() > new Date(timestamp)?.getTime()
    : false;
};

export function getGmailThreadTitle(thread: any) {
  const subject = thread.messages?.[0].subject || "";
  return subject.split(" ").slice(0, 4).join(" ");
}

export function getNotionPageTitle(page: any, truncate: boolean = false) {
  const pageTitle =
    (
      Object.values(page.properties)?.find(
        (prop: any) => prop.id === "title",
      ) as any
    ).title?.[0]?.plain_text || "Untitled";

  if (pageTitle?.length > 50 && truncate) {
    return pageTitle?.slice(0, 50) + "...";
  }
  return pageTitle;
}

export function extractCompanyDomainFromWebpage(
  webpage: string,
): string | null {
  // Parse the <domain>.com part from the domain using regex.
  const match = webpage.match(
    /(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im,
  );
  if (match) {
    console.log("match", match[1]);
    return match[1];
  }
  return null;
}

/**
 * Fuzzy JSON parser.
 * @param text text to parse.
 * @returns The parsed object or undefined if the object could not be parsed.
 */
export function fuzzyParseJSON<TObject = {}>(text: string): any {
  try {
    return JSON.parse(text); // Try regular JSON parser
  } catch {
    // Ignore and continue.
  }
  const startBrace = text.indexOf("{");
  if (startBrace >= 0) {
    // Find substring
    const objText = text.substring(startBrace);
    const nesting = ["}"];
    let cleaned = "{";
    let inString = false;
    for (let i = 1; i < objText.length && nesting.length > 0; i++) {
      let ch = objText[i];
      if (inString) {
        cleaned += ch;
        if (ch == "\\") {
          // Skip escape char
          i++;
          if (i < objText.length) {
            cleaned += objText[i];
          } else {
            // Malformed
            return undefined;
          }
        } else if (ch == '"') {
          inString = false;
        }
      } else {
        switch (ch) {
          case '"':
            inString = true;
            break;
          case "{":
            nesting.push("}");
            break;
          case "[":
            nesting.push("]");
            break;
          case "}":
            const closeObject = nesting.pop();
            if (closeObject != "}") {
              // Malformed
              return undefined;
            }
            break;
          case "]":
            const closeArray = nesting.pop();
            if (closeArray != "]") {
              // Malformed
              return undefined;
            }
            break;
          case "<":
            // The model sometimes fails to wrap <some template> with double quotes
            ch = `"<`;
            break;
          case ">":
            // Catch the tail end of a template param
            ch = `>"`;
            break;
        }

        cleaned += ch;
      }
    }

    // Is the object incomplete?
    if (nesting.length > 0) {
      // Lets close it and try to parse anyway
      cleaned += nesting.reverse().join("");
    }

    // Parse cleaned up object
    try {
      const obj = JSON.parse(cleaned);
      return Object.keys(obj).length > 0 ? obj : undefined;
    } catch (err) {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function removeHtmlTags(str: string): string {
  if (!str) {
    return str;
  }
  return str.replace(/<[^>]*>/g, "");
}
// These are based on default Salesforce stagenames. We can add more as needed or custom logic for custom stage names.
export function salesforceStageNameToDealpageStatus(
  stageName: string,
): string | undefined {
  switch (stageName) {
    case "Prospecting":
      return DEAL_CREATED_STATUS;
    case "Qualification":
      return QUALIFIED_STATUS;
    case "Needs Analysis":
      return PRE_SALES_STATUS;
    case "Value Proposition":
      return PRE_SALES_STATUS;
    case "Id. Decision Makers":
      return PRE_SALES_STATUS;
    case "Perception Analysis":
      return PRE_SALES_STATUS;
    case "Proposal/Price Quote":
      return PRE_SALES_STATUS;
    case "Negotiation/Review":
      return PRE_SALES_STATUS;
    case "Closed Won":
      return WON_STATUS;
    case "Closed Lost":
      return LOST_STATUS;
    default:
      return undefined;
  }
}

export function hubspotStageNameToDealPageStatus(
  stageName: string,
): string | undefined {
  switch (stageName) {
    case "appointmentscheduled":
      return "Appointment Scheduled";
    case "qualifiedtobuy":
      return "Qualified to Buy";
    case "presentationscheduled":
      return "Presentation Scheduled";
    case "decisionmakerboughtin":
      return "Decision Maker Bought In";
    case "contractsent":
      return "Contract Sent";
    case "closedwon":
      return "Closed Won";
    case "closedlost":
      return "Closed Lost";
    default:
      return stageName;
  }
}

export function removeNulls(jsonObj: any) {
  return JSON.parse(
    JSON.stringify(jsonObj, (key, value) => {
      return value === null ? undefined : value;
    }),
  );
}

export function escapeJSONCharacters(jsonString: string) {
  let escapedString = JSON.stringify(jsonString);
  escapedString = escapedString.slice(1, -1);
  escapedString = escapedString.replace(/[\n]/g, "\\n");
  try {
    JSON.parse(escapedString);
  } catch (e) {
    console.log("Invalid JSON string");
  }
  return escapedString;
}

export function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function requirementToString(requirement: RequirementsType) {
  return `We have a ${requirement.type ?? ""} that says item: ${
    requirement.body?.name ?? ""
  } description: ${requirement.body?.description ?? ""} quantity: ${
    requirement.body?.quantity ?? ""
  } Price: ${requirement.body?.amount ?? ""}`;
}

export function engagementToString(engagement: EngagementsType) {
  return `We have a ${engagement.source ?? ""} ${
    engagement.type ?? ""
  } that says title: ${engagement.body?.title ?? ""} text: ${
    engagement.body?.text ?? ""
  }`;
}

export function nextStepToString(nextStep: NextStepType) {
  return `We have a ${nextStep.source ?? ""} ${
    nextStep.type ?? ""
  } that says title: ${nextStep.body?.title ?? ""} text: ${
    nextStep.body?.text ?? ""
  }`;
}

export function extractCompanyDomain(email: string): string | null {
  // Split the email address into username and domain parts
  const [username, domain] = email.split("@");

  // Check if the domain part is valid
  if (!domain) {
    return null;
  }

  // Extract the company domain from the domain part
  const parts = domain.split(".");
  const companyDomain = parts[parts.length - 2] + "." + parts[parts.length - 1];

  // Return the company domain
  return companyDomain;
}

export const convertValues = (
  variable: any,
  transform: (variable: any) => void,
): any => {
  transform(variable);
  if (Array.isArray(variable)) {
    // Recursively apply function to each item in the array
    variable.map((item) => convertValues(item, transform));
  } else if (typeof variable === "object" && variable !== null) {
    // Recursively apply function to each key/value pair
    Object.keys(variable).map((key) => convertValues(variable[key], transform));
  }
};

export function getHtmlFromLexicalJSON(json: string): string {
  const parsed = JSON.parse(json);
  const root = parsed.root;
  return getHtmlFromLexicalNode(root);
}

export function getHtmlFromLexicalNode(node: {
  type: string;
  children?: Array<{ type: string; text?: string; tag?: string }>;
}): string {
  let result = "";
  const { children } = node;
  if (children !== undefined) {
    if (children.length === 0) {
      return result;
    }
    children.forEach((child) => {
      const { type } = child;
      if (type === "paragraph") {
        result += `<p>${getHtmlFromLexicalNode(child)}</p>`;
      } else if (type === "heading") {
        const tag = child.tag ?? "h1";
        result += `<${tag}>${getHtmlFromLexicalNode(child)}</${tag}>`;
      } else if (type === "text") {
        result += `<span>${child.text}</span>`;
      } else {
        result += '<span style="font-weight: bold">Unknown Node</span>';
      }
    });
  }
  return result;
}

export function countLeadingSpacesOfLine(line: string) {
  const leadingSpaces = line.match(/^ */);
  return leadingSpaces ? leadingSpaces[0].length : 0;
}

export function preProcessMarkdown(markdown: string) {
  const lines = markdown.split("\n");

  const processedLines = lines.map((line) => {
    const leadingSpaces = countLeadingSpacesOfLine(line);

    if (leadingSpaces % 4 !== 0) {
      const leadingSpacesToAdd =
        Math.ceil(leadingSpaces / 4) * 4 - leadingSpaces;
      return " ".repeat(leadingSpacesToAdd) + line;
    }

    return line;
  });

  return processedLines.join("\n");
}
